import { graphql } from "gatsby";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Pillar } from "../classes/Pillar";
// import { Course } from "../classes/Course";
import { VideoCat } from "../classes/VideoCat";
import Gallery from "../components/Blocks/Gallery";
import { Typography } from "../components/ui/Typography";
// import {
//   HubUserContext,
//   isValidUser
// } from "../components/context/HubspotContext";
// import CourseGallery from "../components/ui/CourseGallery";
// import { Typography } from "../components/ui/Typography";
// import { CourseQuery } from "../fragments/NodeCourseFields";
import { PillarQuery } from "../fragments/NodePillarFields";
import { VideoCategoryQuery } from "../fragments/NodeVideoCategoryFields";
import HubspotForm from "react-hubspot-form";
import styled from "styled-components";
import { WmkSeo } from "wmk-seo";
import { SiteMetaDataFields } from "../fragments/NodeSiteMetadataFields";
import { useEffect, useState } from "react";
import { WmkLink } from "wmk-link";
import PdfCta from "../components/Blocks/PdfCta";

const PillarsContainer = styled(Container)`
  .newsletter-col {
    padding: 0 30% 5rem;
    .p {
      text-align: center;
      padding: 1rem 0;
    }
    @media screen and (max-width: 767px) {
      padding: 0 15% 5rem;
    }
  }
`;

const courseGallery = ({
  data
}: {
  data: {
    // videoCats: { edges: { node: VideoCategoryQuery }[] };
    gallery: {
      pillars: VideoCategoryQuery[] | PillarQuery[];
    };
    site: SiteMetaDataFields;
  };
}) => {
  const [redirect, setRedirect] = useState(false);
  const [validUser, setValidUser] = useState(false);

  useEffect(() => {
    if (window && localStorage?.getItem("hasCompletedForm")) {
      setValidUser(true);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (redirect) {
        window.location.href = `/`;
      }
    }, 1000);
    if (validUser) {
      clearTimeout(timer);
    }
  }, [redirect, validUser]);

  useEffect(() => {
    setTimeout(() => {
      if (!validUser) {
        setRedirect(true);
      }
    }, 1000);
  }, [validUser]);
  // console.log(data.gallery.coursesList, "data.gallery.coursesList");
  // console.log("data.gallery", data.gallery);
  const videoCats = data.gallery.pillars.map((course) => {
    if ("videos" in course) {
      return new VideoCat(course);
    } else {
      return new Pillar(course);
    }
    // return "description" in course ? new VideoCat(course) : course.videoCategories.map((vidCat) => return);
  });
  // console.log("data", data)
  return validUser ? (
    <>
      <WmkSeo.Meta
        title={"Pillars"}
        siteTitle={data.site.siteMetadata.title}
        baseUrl={data.site.siteMetadata.baseUrl}
        slug="/pillars"
        ogImageUrl=""
        twitterImageUrl=""
        twitterHandle=""
      />
      <PillarsContainer>
        {Array.isArray(videoCats)
          ? videoCats.map((vidCat, i) => {
              return (
                <Gallery
                  data={vidCat instanceof Pillar ? [vidCat] : videoCats}
                  // title={data.gallery.title}
                  subtitle={vidCat instanceof Pillar ? vidCat.title : undefined}
                  largeDisplay
                  key={`${vidCat.title} ${i}`}
                />
              );
            })
          : null}
        <Row>
          <Col className="newsletter-col">
            <Typography.P>
              Subscribe to stay updated on when new courses get added!
            </Typography.P>
            <HubspotForm
              portalId="21124615"
              formId="459628a1-1f63-4ce1-99c2-dacf1d70b295"
            />
          </Col>
        </Row>
      </PillarsContainer>
      <PdfCta />
    </>
  ) : (
    <>
      <Typography.H3 style={{ textAlign: "center", margin: "0 10%" }}>
        Please go to the <WmkLink to="/">home page</WmkLink> and fill out the
        form to access this free content.
      </Typography.H3>
    </>
  );
};

export default courseGallery;

export const query = graphql`
  {
    gallery: contentfulGallery(contentfulid: { eq: "Main Gallery" }) {
      pillars {
        ...NodePillarFields
      }
    }
    site {
      ...NodeSiteMetadata
    }
  }
`;
